<template>
  <div class="row">
    <div class="col-md-12 card p-0">
      <div class="card-body row p-0">
        <div class="col-sm-12">
          <my-filter :rules="rules" v-on:filter-change="updateFilter"></my-filter>
        </div>
        <div class="col-sm-12">
          <my-table :columnDefs="columnDefs" v-bind:data-rows="races" :actions="actions" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { MessageBox, Message } from 'element-ui'
  import MyTable from 'src/components/UIComponents/Table.vue'
  import MyFilter from 'src/components/UIComponents/Filter.vue'
  import raceSchemas from './race-schemas'
  import dtHelper from 'src/helpers/datatable'

  export default{
    components: {
      MyTable,
      MyFilter,
      Message
    },
    computed: {
      races() {
        let data = this.$store.state.allRaces || [];
        return dtHelper.filterByRules(data, this.filterOutput);
      }
    },
    data () {
      return {
        filterOutput: [],
        columnDefs: dtHelper.buildColumDefs(raceSchemas),
        actions: [
          {
            type: 'primary',
            icon: 'nc-icon nc-ruler-pencil',
            callback: this.edit
          },
          {
            type: 'danger',
            icon: 'nc-icon nc-simple-remove',
            callback: this.remove
          }
        ],
        filter: {},
        rules: dtHelper.buildRules(raceSchemas)
      }
    },
    async beforeCreate() {
      await this.$store.dispatch('fetchAllRaces');
      await this.$store.dispatch('setPageTitle', 'Quản lý cuộc đua');
      await this.$store.dispatch('setCurrentActions', [{
        label: 'Tạo mới',
        type: 'primary',
        icon: '',
        callback: this.createRace
      }])
    },
    methods: {
      createRace() {
        this.$router.push('/races/create');
      },
      updateFilter(filterOutput) {
        this.filterOutput = filterOutput;
      },
      edit(index, row) {
        this.$router.push('/races/' + row.id);
      },

      remove(index, row) {
        MessageBox.confirm('Xóa cuộc đua?', 'Warning', {
          confirmButtonText: 'Đồng ý',
          cancelButtonText: 'Hủy bỏ',
          type: 'warning',
          center: true
        }).then(() => {
          this.$store.dispatch('removeRace', row.id).then(res => {
            if (res.success) {
              Message({
                message: res.message,
                type: 'success'
              });
              this.$store.dispatch('fetchAllRaces');
            } else {
              if (res.errors) {
                Message({
                  message: res.errors,
                  type: 'error'
                });
              }
            }
          });
        }, error => {
          console.error(error);
        });
      }
    },
    destroyed(){
      this.$store.dispatch('setCurrentActions', []);
    }
  }
</script>
